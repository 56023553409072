import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import AdvancedSettings from "../settings/advanced_settings.json";
import ServiceHero from "../components/ServiceHero";
import MainContentBlockController from "../components/MainContentBlockController";
import BodyContentRender from "../components/BodyContentRender";
import { parseContent } from '../helpers/city-pages'
import SEO from '../components/SEO'

const ServicePageTemplate = ({
  title,
  metatitle,
  metadescription,
  metakeywords,
  description,
  featuredimage,
  featuredbadges,
  maincontentblocks,
  data,
  city
}) => {
  return (
    <>
      <ServiceHero
        type={AdvancedSettings.serviceherostyle}
        title={title}
        description={description}
        featuredimage={featuredimage}
        featuredbadges={featuredbadges}
      />
      {maincontentblocks
        ? maincontentblocks.map((contentblock, i) => (
            <MainContentBlockController
              key={i}
              contentblockID={contentblock.type}
              data={contentblock}
              isCityPageRequiresTextReplacement={true}
              replaceWithName={city}
            />
          ))
        : null}
    </>
  );
};

const ServiceAreaContentPage = ({ pageContext, data }) => {
  const { tidbitstouse, tidbits, allServiceArea } = pageContext;
  const { frontmatter } = data.markdownRemark;

  const tidbitsFiltered = tidbits.filter(function (tidbit) {
    return tidbit.number === tidbitstouse;
  });

  return (
    <Layout>
        <SEO
        title={parseContent(frontmatter.metatitle, pageContext.citytitle)}
        description={parseContent(frontmatter.metadescription, pageContext.citytitle)}
        img={frontmatter && frontmatter.featuredimage && frontmatter.featuredimage.childImageSharp.fixed.src}

      />

      <ServicePageTemplate
        title={`${parseContent(frontmatter.title, pageContext.citytitle)}, CA`}
        metatitle={parseContent(frontmatter.metatitle, pageContext.citytitle)}
        metadescription={parseContent(frontmatter.metadescription, pageContext.citytitle)}
        metakeywords={frontmatter.metakeywords}
        description={parseContent(frontmatter.description, pageContext.citytitle)}
        featuredimage={frontmatter.featuredimage}
        featuredbadges={frontmatter.headerfeaturedbadges}
        maincontentblocks={frontmatter.maincontentblocks}
        data={data}
        city={pageContext.citytitle}
      />

      <section className="py-12 bg-white">
          <div className="w-full px-4 md:px-6 container mx-auto">
          <h4 className="font-semibold text-lg md:text-2xl mb-3">Buying Homes Fast in {pageContext.citytitle} and Surrounding Areas</h4>
             <div className="flex flex-wrap">{allServiceArea && allServiceArea.map((servicearea, i)=>(
               <Link className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 flex items-center text-gray-800 hover:text-brand-600 font-normal text-base py-3 transition-colors ease-in duration-200" to={`/${frontmatter.slug}-in-${servicearea.node.frontmatter.slug}/`} title={parseContent(frontmatter.title, servicearea.node.frontmatter.title)+', CA'}>
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="opacity-50 w-4 h-4 mr-1">
               <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
             </svg> {servicearea.node.frontmatter.title}, CA</Link>
             ))}
          </div>
          </div>
        </section>


      {tidbitsFiltered && tidbitsFiltered[0] ? (
        <section className="py-12 bg-gray-50">
          <div className="w-full px-4 md:px-6 container mx-auto">
            <h3 className="font-semibold text-lg md:text-2xl mb-5">Frequently Asked Questions</h3>
            <BodyContentRender markdown={tidbitsFiltered[0].body} />

          </div>
        </section>
      ) : null}
    </Layout>
  );
};

export default ServiceAreaContentPage;

export const ServiceAreaContentPageQuery = graphql`
  query ServiceAreaContentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metatitle
        slug
        metadescription
        metakeywords
        description
        headerfeaturedbadges {
          image {
            id
            childImageSharp {
              fluid(maxWidth: 110, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            extension
            publicURL
          }
          imgtitle
        }
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1366, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 1200, height: 630) {
              width
              height
              src
              srcSet
              srcWebp
            }
          }
        }
        maincontentblocks {
          type
          label
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          value
          description
          renderashtml
          contentwithsidebarsidebar {
            sidebar {
              frontmatter {
                title
                bodycontent
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
              }
            }
            sidebarcollection {
              frontmatter {
                title
                sidebars {
                  sidebar {
                    frontmatter {
                      title
                      bodycontent
                      dynamicsidebarid
                      renderashtml
                      outercontainercss
                      id
                    }
                  }
                }
              }
            }
          }
          bodycontent
          photogallery {
            frontmatter {
              title
              description
              images {
                imgtitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 135, height: 118, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }

          footerlogoshowcase {
            frontmatter {
              title
              images {
                imgtitle
                image {
                  extension
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 135, height: 118, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
