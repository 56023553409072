import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import ContactForm from "../components/ContactForm";
import StarsRatingComponent from "../components/StarsRatingsComponent";
import AdvancedSettings from "../settings/advanced_settings.json";

const FORMOFFSETPX = 350;

const ServiceHero = ({
  type,
  title,
  description,
  featuredimage,
  featuredbadges,
  onlineSpecialBadge,
  headerTransparent,
}) => {
  const [headline, setHeadline] = useState("Sell Your Home Fast!");

  useEffect(() => {
    if (typeof window !== "undefined" && window.location.href.includes("land")) {
      setHeadline("Sell Your Land Fast!");
    }
  }, []);

  if (type && type == "spectrum") {
    return <div>Nothing yet </div>;
  }

  const headerOffsetTransparent = 95;

  return (
    <div
      className="overflow-hidden"
      style={{
        marginTop: `${
          headerTransparent ? `${headerOffsetTransparent * -1}px` : "0"
        }`,
      }}
    >
      <div className="h-auto bg-cover relative bg-black ">
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          className="hero-image absolute top-0 h-full"
          style={{ opacity: "1", transform: "none" }}
        />
        <div
          className="relative"
          style={{
            background:
              "linear-gradient(0deg, transparent, rgb(0 0 0/ 10%))",
            paddingTop: `${
              headerTransparent ? `${headerOffsetTransparent}px` : "0"
            }`,
          }}
        >
          <div
            className={`container py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6`}
          >
            <div className="w-full md:w-1/2 lg:w-2/3 py-16 md:pr-8 lg:pr-32 pb-4">
              <div>
                <h1 className="text-shadow-lg text-center md:text-left text-white text-shadow service-heading-tag relative mb-2 text-3xl md:text-4xl font-bold leading-10">
                  {title}
                </h1>
                {description ? (
                  <p className="text-shadow-md text-center md:text-left text-white mt-1 text-lg leading-7 ">
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </p>
                ) : null}
              </div>
              <div className="lg:py-3">
                <StarsRatingComponent
                  centerOnMobile={true}
                  className={"mt-8"}
                  reviewavg={AdvancedSettings.reviewavg}
                  reviewscount={AdvancedSettings.reviewscount}
                  enabled={AdvancedSettings.ratingstarsdisplayenabled}
                />
              </div>
              {featuredbadges ? (
                <div className="flex items-center md:flex-wrap mt-3 w-full">
                  {featuredbadges.map((badge, b) => {
                    if (badge.image.extension === "svg") {
                      return (
                        <img
                          key={b}
                          className="w-1/4 p-3 md:w-1/2 lg:w-1/4"
                          style={{ maxWidth: "110px", maxHeight: "110px" }}
                          src={badge.image.publicURL}
                          alt={badge.imgtitle}
                        />
                      );
                    } else {
                      return (
                        <div
                          key={b}
                          className="w-1/4 p-3 md:w-1/2 lg:w-1/4"
                          style={{ maxWidth: "110px", maxHeight: "110px" }}
                        >
                          <Img
                            alt={badge.imgtitle}
                            fluid={badge.image.childImageSharp.fluid}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 form-offset">
              <div className="bg-white rounded-xl p-8 pb-6 border-brand-500 md-mb-none shadow-xl relative">
                <div className="mb-4">
                  <h3 className="font-display leading-6 md:leading-7 font-bold text-2xl text-gray-800">
                    {headline} Get Your Fair Cash Offer: Start Below!
                  </h3>
                  <p className="text-gray-600 mb-2 leading-6 mt-1">
                    No Fees. No Commissions. Put More Cash In Your Pocket.
                  </p>
                </div>
                <ContactForm
                  hideLabels={true}
                  showPlaceholders={true}
                  footerContained={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: FORMOFFSETPX + "px" }} className="height-none-md" />
    </div>
  );
};

export default ServiceHero;
