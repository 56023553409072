import React, { useState, useEffect, useCallback } from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
 
const SidingOneThousandPromotion = () => (
  <StaticQuery
    query={graphql`
      query {
        bookImage: file(relativePath: { eq: "selling-to-a-professional.jpeg" }) {
          childImageSharp {
            fixed(width: 140) {
              ...GatsbyImageSharpFixed
              src
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div
          className={`rounded-lg mb-2 shadow bg-white`}
          style={{
            background: "linear-gradient(160deg, #00508d 50%, #F4F9FE 50%)",
          }}
        >
          <div className="px-8 pt-6 text-center">
            <h2 className="text-white font-bold text-3xl md:text-4xl">
            FREE Guide
            </h2>
            <h2 className="text-white font-semibold text-base md:text-lg leading-6 mb-4">
            Learn The Pros and Cons Of Selling Your House To Local Sacramento Professional Home Buyer


            </h2>
         
          </div>

          <div className="flex items-center my-3">
            <Img
              fixed={data.bookImage.childImageSharp.fixed}
              alt="Free Book"
              className="mx-auto shadow-xl"
            />
           
          </div>
          <div className="flex items-center pb-4">
          <Link
              to={"/go/selling-to-a-professional/"}
              className="button-active-bhvr my-2  text-center mx-auto inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700  focus:outline-none px-5 py-2  rounded-full  bg-white shadow font-display"
            >
             Get The Guide
            </Link>
            </div></div>

            
      );
    }}
  />
);

export default SidingOneThousandPromotion;
